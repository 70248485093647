import Vue from 'vue'
import moment from "moment";

import VueCryptojs from 'vue-cryptojs'

Vue.use(VueCryptojs)

Vue.mixin({
  methods: {
    scrollToElement(id) {
      const el = this.$el.getElementsByClassName(id)[0];
      if (el) {
        el.scrollIntoView({
          behavior: 'smooth'
        });
      }
    },
    scrollToElementInViews(id) {
      const el = this.$el.getElementsByClassName(id)[0];
      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({
          behavior: 'smooth',
          block: "center"
        });
      }
    },
    scrollToHeaderView(id) {
      var el = this.$el.getElementsByClassName(id)[0];
      if (el && window.innerWidth < 991) {
        el.scrollIntoView(true);
        window.scrollBy(0, -70);
      } else {
          window.scrollTo(0, 0)
      }
    },
    scrollToSectionIntoView(id) {
      var el = this.$el.getElementsByClassName(id)[0];
      if (el) {
        el.scrollIntoView(true);
        window.scrollBy(0, -100);
      }
    },
    scrollToJs(element) {
      var el = document.getElementById(element)
      if(el){
        if(window.innerWidth < 991){
          window.scroll({
            behavior: 'smooth',
            left: 0,
            top: 200
          });
        }else{
          window.scroll({
            behavior: 'smooth',
            left: 0,
            top: 500
          });
        }
      
    }
    },
    scrollToJsOffers(element) {
      var el = document.getElementById(element)
      if(el){
        if(window.innerWidth > 991){
          window.scroll({
            behavior: 'smooth',
            left: 0,
            top: el.offsetTop - 72
          });
    }
    else{
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: el.offsetTop - 50
      });
    }
    }
    },
    scrollToJsEvents(element) {
      var el = document.getElementById(element)
      if(el){
        if(window.innerWidth > 991){
          window.scroll({
            behavior: 'smooth',
            left: 0,
            top: el.offsetTop + 370 
          });
    }
    else{
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: el.offsetTop + 130
      });
    }
    }
    },
    scrollToJsRentals(element) {
      var el = document.getElementById(element)
      if(el){
        if(window.innerWidth < 991){
          window.scroll({
            behavior: 'smooth',
            left: 0,
            top: 560
          });
        }else{
          window.scroll({
            behavior: 'smooth',
            left: 0,
            top: 500
          });
        }
      
    }
    },
    checkTodayOpen(openingHoursRevceived) {
      var today = new Date();
      var weekday = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday"
      ];
      // get today week day
      var dayOfWeek = weekday[today.getDay()];
      // generater filter object to store opening hours details
      const filterOpeningHours = Object.entries(openingHoursRevceived).map(e => ({
        ["day"]: e[0],
        ["time"]: e[1]
      }));
      // find todays opening hour details from the object generated above
      const filteredHours = filterOpeningHours.filter(
        day => day.day.toLowerCase() == dayOfWeek.toLowerCase()
      );
      let filteredHoursObject = filteredHours[0];
      // check if valid time is passed
      if (filteredHoursObject.time.includes("-")) {
        let timeArr = filteredHoursObject.time.split("-");
        var currentTime = moment(today, "HH:mm a");
        if (
          (timeArr[0].toLowerCase().includes("am") ||
            timeArr[0].toLowerCase().includes("pm")) &&
          (timeArr[1].toLowerCase().includes("am") ||
            timeArr[1].toLowerCase().includes("pm"))
        ) {
          var startTime = moment(timeArr[0].trim(), "HH:mm a");
          var endTime = moment(timeArr[1].trim(), "HH:mm a");
          if (
            (startTime.hour() >= 12 && endTime.hour() <= 12) ||
            endTime.isBefore(startTime)
          ) {
            endTime.add(1, "days"); // handle spanning days endTime
            if (currentTime.hour() <= 12) {
              currentTime.add(1, "days"); // handle spanning days currentTime
            }
          }
          return currentTime.isBetween(startTime, endTime);
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    getCryptKey(key){
      if(this.$config.production){
      const decryptedText = this.$CryptoJS.AES.decrypt(key, this.$config.cryptoKey).toString(this.$CryptoJS.enc.Utf8)
      return decryptedText ? JSON.parse(decryptedText) : null
      }
      else{
        try {
          JSON.parse(key);
      } catch (e) {
          return key;
      }
      return JSON.parse(key);
      }
     },
     getRandomNumber(number){
      let num = number + 1;
      let mod = num%8
      if(mod < 7){
        return Math.floor(mod) + 1
      }
      else{
        return mod - 7 != 0 ? mod - 7 : 1;
      }
    },
    //dates for all, today, tomorrow
    getDates(day){
      let ndate = new Date()
      switch(day) {
        case 'today':
        return moment(ndate).format("ddd, DD MMM");
        case 'tomorrow':
        return moment(ndate).add(1, 'days').format('ddd, DD MMM').toString();
        case 'weekend':
        return moment(ndate).day(6).format('DD') + ' - ' + moment(ndate).day(7).format('DD MMM')
        default:
          return moment(ndate).format("ddd, DD MMM");
      }
    },

    getCardDesc(text){
      if(text){
        return text.replace(/<[^>]*>?/gm, ' ');
      }
      return text
    },

    getSpace(key) {
      return key.replace(/([A-Z])/g, " $1")
    },
    getHtmlEncode(data) {
      return data.replace(/\n/g, '<br/>')
    },

    convertNewLineToArr(strng) {
      let arr = strng.split("\n")
      arr = arr.filter(item => item);
      return arr
    },
    string_to_slug(str) {
      str = str.replace(/^\s+|\s+$/g, ''); // trim
      str = str.toLowerCase();
      // remove accents, swap ñ for n, etc
      var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
      var to = "aaaaaeeeeiiiioooouuuunc------";
      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }
      str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes
      return str;
    },
    getOfferPercentage(strike, price){
      if(strike > price){
      let offerPercentage = strike - price;
      if (offerPercentage > 0) {
        return ((100 * (strike - price) / strike) > 0 && (100 * (strike - price) /
        strike) < 100) ? parseFloat((100 * (strike - price) / strike).toFixed(0)) : 0
      } else return 0
    }
    else return 0
    }
  }
})